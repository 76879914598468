import React, { useState, useEffect, useCallback, useMemo } from 'react';
import {
  AppBar, Toolbar, Dialog, Button, Card, CardContent, Drawer,
  FormControl, InputLabel, MenuItem, Select, TextField, Box, IconButton,
  InputBase, CircularProgress, Typography, DialogTitle, DialogContent,
  Radio, RadioGroup, FormControlLabel, Container
} from '@mui/material';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import { styled } from '@mui/material/styles';
import dayjs from 'dayjs';
import { getEventsByDate } from '../controller.tsx';



const Search = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  backgroundColor: "#f1f3f4",
  padding: "6px 12px",
  borderRadius: "20px",
  width: "100%",
  maxWidth: "500px",
}));

const SkeletonWebPage = () => {
  const today = useMemo(() => dayjs(), []);
  const [date, setDate] = useState(today);
  const [aDate, setADate] = useState(today.format('YYYY-MM-DD'));
  const [compSearch, setCompSearch] = useState('');
  const [categoryFilter, setCategoryFilter] = useState('');
  const [loading, setLoading] = useState(true);
  const [dateEvents, setDateEvents] = useState([]);
  const [filteredEvents, setFilteredEvents] = useState([]);
  const [isSortBy, setIsSortBy] = useState(false);
  const [sortBy, setSortBy] = useState('default');
  const [isEventModal, setIsEventModal] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState({});

  const loadDateEvents = useCallback(async () => {
    setLoading(true);
    const events = await getEventsByDate(aDate);
    setDateEvents(events);
    setLoading(false);
  }, [aDate]);

  useEffect(() => {
    loadDateEvents();
  }, [loadDateEvents]);

  useEffect(() => {
    setFilteredEvents(dateEvents.filter(event =>
      event.name.toLowerCase().includes(compSearch.toLowerCase())
    ));
  }, [dateEvents, compSearch]);

  const handleDateChange = (newDate) => {
    if (newDate) {
      setDate(newDate);
      setADate(newDate.format('YYYY-MM-DD'));
    }
  };

  const handleSortBy = () => {
    if(sortBy === 'default'){
        filteredEvents.sort((a,b) =>{
            if (a.id < b.id){
                return -1;
            } else if (a.id > b.id){
                return 1;
            }
            return 0;
        })
    } else if (sortBy === 'alpha'){
        filteredEvents.sort((a,b) =>{
            if (a.name < b.name){
                return -1;
            } else if (a.name > b.name){
                return 1;
            }
            return 0;
        })
    }
    else if (sortBy === 'distance'){
        // todo calculate distance given address of location and location of user
    }
    else if (sortBy === 'location') {
        filteredEvents.sort((a,b) =>{
            if (a.location < b.location){
                return -1;
            } else if (a.location > b.location){
                return 1;
            }
            return 0;
        })
    }
    setIsSortBy(false);

  }

  const formatTime = (d) => new Date(d).toLocaleString('en-US', {
    weekday: 'short',
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  });
    return(
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <AppBar position="static" color="primary" elevation={2}>
            <Toolbar sx={{ display: "flex", justifyContent: "space-between", gap: 2 }}>
              <Button onClick={() => setIsSortBy(true)} variant="contained">Sort By</Button>

              <Dialog open={isSortBy} onClose={() => setIsSortBy(false)}>
                <DialogTitle>Sort By</DialogTitle>
                <DialogContent>
                  <FormControl component="fieldset">
                    <RadioGroup value={sortBy} onChange={(e) => setSortBy(e.target.value)}>
                      <FormControlLabel value="default" control={<Radio />} label="Default" />
                      <FormControlLabel value="alpha" control={<Radio />} label="Alphabetical" />
                      <FormControlLabel value="location" control={<Radio />} label="Location" />
                    </RadioGroup>
                  </FormControl>
                  <Button variant="contained" sx={{ mt: 2 }} onClick={handleSortBy}>Sort</Button>
                </DialogContent>
              </Dialog>

              <Search>
                <SearchIcon sx={{ mr: 1 }} />
                <InputBase
                  placeholder="Search events..."
                  fullWidth
                  value={compSearch}
                  onChange={(e) => setCompSearch(e.target.value)}
                />
              </Search>

                <DatePicker
                  value={date}
                  onChange={handleDateChange}
                  slotProps={{
                    textField: {
                      variant: 'outlined',
                      sx: {
                        backgroundColor: 'background.paper',
                        borderRadius: 2,
                        '& .MuiInputBase-input': { color: 'text.primary' },
                      },
                    },
                  }}
                />
              <FormControl fullWidth>
                <InputLabel>Category</InputLabel>
                <Select
                    value={categoryFilter}
                    onChange={(e) => setCategoryFilter(e.target.value)}
                    sx={{
                      color: 'text.primary',
                      '& .MuiSelect-icon': { color: 'text.secondary' },
                      backgroundColor: 'background.paper',
                    }}
                  >
                  <MenuItem value="">None</MenuItem>
                  <MenuItem value="sports">Sports</MenuItem>
                  <MenuItem value="nightlife">Nightlife</MenuItem>
                  <MenuItem value="other">Other</MenuItem>
                </Select>
              </FormControl>
            </Toolbar>
          </AppBar>

          <Box sx={{ minHeight: '100vh', p: 2 }}>
            {loading ? (
              <Box display="flex" justifyContent="center" mt={4}>
                <CircularProgress />
              </Box>
            ) : (
              filteredEvents.map(event => (
                <Card
                  key={event.id}
                  sx={{
                    mb: 2,
                    p: 2,
                    borderRadius: 2,
                    boxShadow: 2,
                    cursor: 'pointer',
                    '&:hover': { boxShadow: 4 },
                  }}
                  onClick={() => {
                    setSelectedEvent(event);
                    setIsEventModal(true);
                  }}
                >
                  <CardContent>
                    <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                      <Box sx={{
                        width: 80,
                        height: 80,
                        overflow: 'hidden',
                        borderRadius: 2,
                        flexShrink: 0
                      }}>
                        <img src={event.logo} alt="Event"
                          style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                        />
                      </Box>
                      <Box sx={{ pl: 3 }}>
                        <Typography variant="h6" fontWeight="bold">{event.name}</Typography>
                        <Typography variant="body2" color="text.secondary">{event.location}</Typography>
                        <Typography variant="body2" color="text.secondary">{event.time_string}</Typography>
                      </Box>
                    </Box>
                  </CardContent>
                </Card>
              ))
            )}
          </Box>

          <Drawer
            anchor="right"
            open={isEventModal}
            onClose={() => setIsEventModal(false)}
            ModalProps={{ keepMounted: true }}
            BackdropProps={{ sx: { backgroundColor: "transparent" } }}
            PaperProps={{
              sx: {
                width: 500,
                borderRadius: 2,
                m: 2,
                p: 2,
                boxShadow: 3,
              },
            }}
          >
            <Box p={2}>
              <IconButton onClick={() => setIsEventModal(false)}>
                <CloseIcon />
              </IconButton>

              <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
                <img
                  src={selectedEvent?.logo}
                  alt="Event Logo"
                  style={{
                    width: '100%',
                    maxWidth: 400,
                    height: 200,
                    objectFit: 'cover',
                    borderRadius: '8px',
                  }}
                />
              </Box>

              <Typography variant="h6" fontWeight="bold">{selectedEvent?.name}</Typography>
              <Typography>{selectedEvent?.description || "No description available"}</Typography>
              <Typography variant="body2" color="text.secondary">{selectedEvent?.location}</Typography>
              <Typography variant="body2" sx={{ fontStyle: 'italic' }}>{selectedEvent?.address}</Typography>
              <Typography>{selectedEvent?.time_string}</Typography>
              <Typography>{formatTime(selectedEvent?.time_with_zone)}</Typography>

              <a
                href={selectedEvent?.link}
                target="_blank"
                rel="noopener noreferrer"
                style={{ textDecoration: 'none' }}
              >
                <Button variant="text" color="secondary">
                  {selectedEvent?.link}
                </Button>
              </a>
            </Box>
          </Drawer>
        </LocalizationProvider>
    )
//  return (
//    <LocalizationProvider dateAdapter={AdapterDayjs}>
//      <AppBar position="static" color="default" elevation={2}>
//        <Toolbar sx={{ display: "flex", justifyContent: "space-between", gap: 2 }}>
//          <Button onClick={() => setIsSortBy(true)} variant="contained">Sort By</Button>
//          <Dialog open={isSortBy} onClose={() => setIsSortBy(false)}>
//            <DialogTitle>Sort By</DialogTitle>
//            <DialogContent>
//              <FormControl component="fieldset">
//                <RadioGroup value={sortBy} onChange={(e) => setSortBy(e.target.value)}>
//                  <FormControlLabel value="default" control={<Radio />} label="Default" />
//                  <FormControlLabel value="alpha" control={<Radio />} label="Alphabetical" />
//                  <FormControlLabel value="location" control={<Radio />} label="Location" />
//                </RadioGroup>
//              </FormControl>
//              <Button variant="contained" sx={{ mt: 2 }} onClick={() => setIsSortBy(false)}>Sort</Button>
//            </DialogContent>
//          </Dialog>
//          <Search>
//            <SearchIcon sx={{ marginRight: 1 }} />
//            <InputBase placeholder="Search events..." fullWidth value={compSearch} onChange={(e) => setCompSearch(e.target.value)} />
//          </Search>
//          <DatePicker value={date} onChange={handleDateChange} />
//          <FormControl fullWidth>
//            <InputLabel>Category</InputLabel>
//            <Select value={categoryFilter} onChange={(e) => setCategoryFilter(e.target.value)}>
//              <MenuItem value={''}>None</MenuItem>
//              <MenuItem value={'sports'}>Sports</MenuItem>
//              <MenuItem value={'nightlife'}>Nightlife</MenuItem>
//              <MenuItem value={'other'}>Other</MenuItem>
//            </Select>
//          </FormControl>
//        </Toolbar>
//      </AppBar>
//      <Box sx={{ minHeight: '100vh', padding: 2 }}>
//        {loading ? (
//          <Box display="flex" justifyContent="center" mt={4}><CircularProgress /></Box>
//        ) : (
//          filteredEvents.map(event => (
//            <Card key={event.id} sx={{ mb: 2, p: 2 }}
//                onClick={()=>{
//                setSelectedEvent(event);
//                setIsEventModal(true);
//            }}>
//              <CardContent>
//                  <Box sx={{display:'flex', flexDirection: 'row'}}>
//                    <Box sx={{ width: 80, height: 80, overflow: 'hidden', borderRadius: '8px' }}>
//                      <img src={event.logo} alt="Event" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
//                    </Box>
//                    <Box sx={{paddingLeft: 5}}>
//                        <Typography variant="h6" fontWeight="bold">{event.name}</Typography>
//                        <Typography variant="body2" color="textSecondary">{event.location}</Typography>
//                        <Typography variant="body2" color="textSecondary">{event.time_string}</Typography>
//                    </Box>
//                  </Box>
//              </CardContent>
//            </Card>
//          ))
//        )}
//      </Box>
//      <Drawer anchor="right" open={isEventModal} onClose={() => setIsEventModal(false)}
//          ModalProps={{ keepMounted: true }}
//          BackdropProps={{ sx: { backgroundColor: "transparent" } }}
//          PaperProps={{
//            sx: {
//              width: 500,
//              borderRadius: "12px",
//              m: 2,
//              p: 2,
//              boxShadow: 3,
//            },
//          }}
//      >
//        <Box p={2}>
//          <IconButton onClick={() => setIsEventModal(false)}><CloseIcon /></IconButton>
//          <Box sx={{display:'flex', justifyContent:'center', marginBottom: 2}}>
//            <img
//                src={selectedEvent?.logo}
//                alt="Event Logo"
//                style={{
//                    width: '100%',
//                    maxWidth: 400,
//                    height: 200,
//                    objectFit: 'cover',
//                    borderRadius: '8px', // Adding border radius for a nicer effect
//                }}
//            />
//          </Box>
//          <Typography variant="h6" fontWeight="bold">{selectedEvent?.name}</Typography>
//          <Typography>{selectedEvent?.description || "No description available"}</Typography>
//          <Typography variant="body2">{selectedEvent?.location}</Typography>
//          <Typography variant="body2" sx={{ fontStyle: 'italic' }}>{selectedEvent?.address}</Typography>
//          <Typography>{selectedEvent?.time_string}</Typography>
//          <Typography>{formatTime(selectedEvent?.time_with_zone)}</Typography>
//          <a href={selectedEvent?.link} target="_blank" rel="noopener noreferrer" style={{ color: '#37474F', textDecoration: 'none' }}>
//            <Button variant="text" sx={{ color: '#37474F' }}>
//              {selectedEvent?.link}
//            </Button>
//          </a>
//        </Box>
//      </Drawer>
//    </LocalizationProvider>
//  );
};

export default SkeletonWebPage;
