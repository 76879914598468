import axios from 'axios';

const baseUrl = 'https://aroundeverythingquery-production.up.railway.app'
export const getEvents = async () =>{
    try {
        const response = await axios.get('https://aroundeverythingquery-production.up.railway.app/api/events')
        return response.data;
    } catch (error) {
        console.error(error);
        console.log(' its a query error ');
        return;
    }
};

export const getEventsByDate = async (date) => {
    const response = await axios.get(`${baseUrl}/api/events/date/${date}`)
    return response.data;
}