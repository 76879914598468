import React from 'react';
import { Box, Typography, Button } from '@mui/material';
import { useParams, useNavigate, Link } from 'react-router-dom';

const SkeletonEvent = () => {
  const { id, name, description, location, address, logo, date, time_string, duration, link } = useParams();
  const navigate = useNavigate(); // Hook for programmatically navigating

  const formattedTime = new Date(date).toLocaleString('en-US', {
    weekday: 'short',
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  });

  // Navigate back to home page
  const handleBackToHome = () => {
    navigate('/'); // This will navigate to the home page ("/")
  };

  return (
    <Box sx={{ backgroundColor: '#ECEFF1', paddingTop: 2 }}>
      <Box sx={{ display: 'flex', justifyContent: 'center', marginBottom: 2 }}>
        <img
          src={logo}
          alt="Event Logo"
          style={{
            width: '100%',
            maxWidth: 400,
            height: 200,
            objectFit: 'cover',
            borderRadius: '8px', // Adding border radius for a nicer effect
          }}
        />
      </Box>
      <Box sx={{
        backgroundColor: '#FFFFFF',
        padding: 3,
        borderRadius: 2,
        boxShadow: 3,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: 2,
        '@media (max-width: 600px)': {
          padding: 2,
        },
      }}>
        <Typography variant="h6" sx={{
          textAlign: 'center', fontWeight: 'bold', color: '#37474F', paddingY: 1, fontSize: '1.2rem',
          '@media (max-width: 600px)': {
            fontSize: '1rem', // Adjust font size for smaller screens
          },
        }}>
          {name}
        </Typography>
        {description != " " ? (
          <Typography sx={{ paddingBottom: 2, color: '#455A64' }}>
            {description}
          </Typography>
        ) : (
          <Typography sx={{ paddingBottom: 2, fontStyle: 'italic', color: '#888' }}>
            No description available
          </Typography>
        )}
        <Box sx={{ paddingY: 2 }}>
          <Typography variant="body2" sx={{ color: '#607D8B', fontStyle: 'italic' }}>
            {location}
          </Typography>
          <Typography variant="body2" sx={{ color: '#607D8B', fontStyle: 'italic' }}>
            {address}
          </Typography>
        </Box>
        <Typography sx={{ color: '#37474F', paddingBottom: 1 }}>
          {formattedTime}
        </Typography>
        <Typography sx={{ color: '#37474F', paddingBottom: 1 }}>
          {time_string}
        </Typography>
        <a href={link} target="_blank" rel="noopener noreferrer" style={{ color: '#37474F', textDecoration: 'none' }}>
          <Button variant="text" sx={{ color: '#37474F' }}>
            {link}
          </Button>
        </a>

        <Button
          variant="contained"
          color="primary"
          onClick={handleBackToHome}
          sx={{
            marginTop: 3,
            width: '100%', // Full width button
            padding: '10px 0', // Adjust padding for better touch area
            fontSize: '1.1rem',
            '@media (max-width: 600px)': {
              fontSize: '1rem', // Smaller font size for mobile
              padding: '12px 0', // Adjust padding for small screens
            },
          }}
        >
          Back to Home
        </Button>
      </Box>
    </Box>
  );
};

export default SkeletonEvent;
