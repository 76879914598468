import { createTheme, ThemeProvider } from '@mui/material/styles';

const darkModeTheme = createTheme({
  palette: {
    mode: 'dark',
    background: {
      default: '#121212',       // Dark background
      paper: '#1E1E1E',         // Card backgrounds
    },
    text: {
      primary: '#E5E7EB',       // Light gray for text
      secondary: '#9CA3AF',     // Muted gray for secondary text
    },
    primary: {
      main: '#3B82F6',          // Bright blue (CTA, nav, logo)
      contrastText: '#FFFFFF',  // White text for primary elements
    },
    secondary: {
      main: '#F59E0B',          // Muted amber for secondary elements
      contrastText: '#FFFFFF',  // White text for secondary elements
    },
    error: {
      main: '#F87171',          // Light red for error states
    },
    divider: '#D1D5DB',        // Light gray borders
  },

  typography: {
    fontFamily: ['Roboto', 'Helvetica', 'Arial', 'sans-serif'].join(','),
    h1: {
      fontWeight: 700,
      fontSize: '2rem',
    },
    h2: {
      fontWeight: 600,
      fontSize: '1.5rem',
    },
    body1: {
      fontSize: '1rem',
      color: '#E5E7EB',         // Light gray text
    },
    body2: {
      fontSize: '0.875rem',
      color: '#9CA3AF',         // Muted gray text
    },
  },

  shape: {
    borderRadius: 16,          // Soft, modern curves for all components
  },

  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 16,          // Rounded corners for the button
          textTransform: 'none',     // Normal text (streetwear vibe)
          fontWeight: 600,           // Slightly bold
          padding: '8px 16px',       // Comfortable padding
          fontSize: '1rem',          // Font size for consistency
          '&:hover': {
            backgroundColor: '#3B82F6',  // Hover background color
            opacity: 0.8,                 // Slight opacity change on hover
          },
          '&.Mui-disabled': {
            backgroundColor: '#6B7280',  // Disabled button color
            color: '#E5E7EB',             // Disabled text color
          },
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: 16,
          padding: '1rem',
        },
      },
    },
  },
});

export default darkModeTheme
