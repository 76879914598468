import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
} from "react-router-dom";

import { ThemeProvider, CssBaseline } from '@mui/material';
import streetwearTheme from './Styles/streetwearTheme.js';
import darkModeTheme from './Styles/darkModeTheme.js';
import lightModeTheme from './Styles/lightModeTheme.js';

import SkeletonPage from './Pages/SkeletonPage.js';
import SkeletonEvent from './Pages/SkeletonEvent.js';
import SkeletonWebPage from './Pages/SkeletonWebPage.js';

function App() {
  const getDimensions = () => {
    const { innerWidth: width, innerHeight: height } = window;
    return { width, height };
  };

  const [windowDimensions, setWindowDimensions] = useState(getDimensions());

  useEffect(() => {
    const handleResize = () => {
      setWindowDimensions(getDimensions());
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <ThemeProvider theme={darkModeTheme}>
      <CssBaseline />
      {windowDimensions.width <= 416 ? (
        <Router>
          <Routes>
            <Route
              path="/"
              element={<SkeletonPage />}
            />
            <Route
              path="/SkeletonEvent/:id/:name/:description/:location/:address/:logo/:date/:time_string/:duration/:link"
              element={<SkeletonEvent />}
            />
          </Routes>
        </Router>
      ) : (
        <SkeletonWebPage />
      )}
    </ThemeProvider>
  );
}

export default App;
