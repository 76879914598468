import { createTheme } from '@mui/material/styles';

const lightModeTheme = createTheme({
  palette: {
    mode: 'light',
    background: {
      default: '#FFFFFF',    // White background
      paper: '#F9FAFB',      // Soft off-white for cards
    },
    text: {
      primary: '#111827',    // Charcoal Black for primary text
      secondary: '#6B7280',  // Cool Gray for secondary text
    },
    primary: {
      main: '#2563EB',       // Royal Blue for accent/CTA
      contrastText: '#FFFFFF',
    },
    error: {
      main: '#EF4444',       // Red-500 for errors/alerts
    },
    divider: '#D1D5DB',      // Light gray borders
  },

  typography: {
    fontFamily: ['Inter', 'Poppins', 'sans-serif'].join(','),
    h1: {
      fontWeight: 700,
      fontSize: '2rem',
    },
    h2: {
      fontWeight: 600,
      fontSize: '1.5rem',
    },
    body1: {
      fontSize: '1rem',
      color: '#111827',
    },
    body2: {
      fontSize: '0.875rem',
      color: '#6B7280',
    },
  },

  shape: {
    borderRadius: 16,  // 1rem for soft, modern curves
  },

  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 16,
          textTransform: 'none', // Keep text normal (streetwear vibe)
          fontWeight: 600,
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: 16,
          padding: '1rem',
        },
      },
    },
  },
});

export default lightModeTheme;
