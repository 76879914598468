import { createTheme } from '@mui/material/styles';

const streetwearTheme = createTheme({
  palette: {
    mode: 'light',
    background: {
      default: '#FAFAFA',    // Soft gray-white
      paper: '#E5E7EB',      // Card backgrounds
    },
    text: {
      primary: '#1F2937',    // Deep charcoal
      secondary: '#6B7280',  // Muted gray
    },
    primary: {
      main: '#0F172A',       // Navy black (CTA, nav, logo)
      contrastText: '#FFFFFF',
    },
    secondary: {
      main: '#F59E0B',       // Muted amber (like, follow)
      contrastText: '#FFFFFF',
    },
    error: {
      main: '#EF4444',       // Muted red (delete, alerts)
    },
    divider: '#D1D5DB',      // Light gray borders
  },

  typography: {
    fontFamily: ['Inter', 'Poppins', 'sans-serif'].join(','),
    h1: {
      fontWeight: 700,
      fontSize: '2rem',
    },
    h2: {
      fontWeight: 600,
      fontSize: '1.5rem',
    },
    body1: {
      fontSize: '1rem',
      color: '#1F2937',
    },
    body2: {
      fontSize: '0.875rem',
      color: '#6B7280',
    },
  },

  shape: {
    borderRadius: 16,  // 1rem for soft, modern curves
  },

  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 16,
          textTransform: 'none', // Keep text normal (streetwear vibe)
          fontWeight: 600,
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: 16,
          padding: '1rem',
        },
      },
    },
  },
});

export default streetwearTheme;