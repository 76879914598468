import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { Container, TextField, Button, Card, CardContent, Drawer, List, ListItem, ListItemIcon, ListItemText, ListItemButton, Typography, Modal, Box, IconButton, CircularProgress, AppBar, Toolbar, BottomNavigation, BottomNavigationAction, FormControl, FormLabel, FormControlLabel, RadioGroup, Radio, Checkbox } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers';
import { Close as CloseIcon, Menu as MenuIcon, Restore as RestoreIcon, Favorite as FavoriteIcon, LocationOn as LocationOnIcon } from '@mui/icons-material';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';

import dayjs from 'dayjs';
import { Link } from "react-router-dom";
import { getEventsByDate } from '../controller.tsx'
import emailjs from 'emailjs-com';

import { useTheme } from '@mui/material/styles';

export default function SkeletonPage() {
  const theme = useTheme();
  const [value, setValue] = useState(0);

  const [compSearch, setCompSearch] = useState('');
  const [isDonate, setIsDonate] = useState(false);
  const [isNewsletter, setIsNewsletter] = useState(false);  // make description of benefits of making an account
  const [isSortBy, setIsSortBy] = useState(false);  // alphabetical, category, location, distance
  const [sortBy, setSortBy] = useState('default');
  const [isToggleDrawer, setIsToggleDrawer] = useState(false);

  const today = useMemo(() => dayjs(), []);
  const [date, setDate] = useState(today);
  const [aDate, setADate] = useState(today.format('YYYY-MM-DD'));
  const [dateEvents, setDateEvents] = useState([]);
  const [loading, setLoading] = useState(true);

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [company, setCompany] = useState('');
  const [email, setEmail] = useState('')
  const [aboutYou, setAboutYou] = useState('');

  const [filteredEvents, setFilteredEvents] = useState([])

  const [isCompOnlyChecked, setIsCompOnlyChecked] = useState(false);

  const loadDateEvents = useCallback(async () => {
    setLoading(true);
    const events = await getEventsByDate(aDate);
    setDateEvents(events);
    setLoading(false);
  }, [aDate]);

  useEffect(() => {
    loadDateEvents();
  }, [loadDateEvents]);

  useEffect(() => {
    setFilteredEvents(dateEvents.filter(event => event.name.toLowerCase().includes(compSearch.toLowerCase())));
  },[dateEvents, compSearch])

  const handleDateChange = (newDate) => {
    if (newDate) {
      setDate(newDate);
      setADate(newDate.format('YYYY-MM-DD'));
    }
  };

  // TODO: USE A COMPANY'S cID  TO DETERMINE IF IT IS A COMPANY OR NOT
  const handleIsOnlyCompany = (e) => {
    const checked = e.target.checked
    setIsCompOnlyChecked(checked);

    if(checked){
        let out = filteredEvents.filter((event)=>{
            if (event.cID && event.cID.length>0){
                return true;
            } else {
                return false;
            }
        })
        setFilteredEvents(out)
    } else {
        setFilteredEvents(dateEvents.filter(event => event.name.toLowerCase().includes(compSearch.toLowerCase())));
    }
  }

    // not using atm
    const [isSuccess, setIsSuccess] = useState(false);
    const [isFail, setIsFail] = useState(false);

  const SERVICE_ID="service_wfyx10g";
  const TEMPLATE_ID="template_kn88jc6";
  const PUBLIC_KEY="z9m2tkKM03ejz9Jdu";
  const sendEmail = (e) => {
    if(firstName==='' || lastName==='' || email==='' || aboutYou===''){
        return;
    }
    if(!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)){
        return;
    }
    const message = `COMPANY: ${company}\nDESCRIPTION: ${aboutYou}`;
    const params = {
        to_name: 'Brian Huhn',
        from_name: `${firstName} ${lastName}`,
        message: aboutYou,
        reply_to: email
    }
    emailjs.send(SERVICE_ID, TEMPLATE_ID, params, PUBLIC_KEY)
        .then((result) => {
            setFirstName('');
            setLastName('');
            setEmail('');
            setAboutYou('');
            setCompany('');
            setIsSuccess(true);
            setIsFail(false);
            setIsNewsletter(false);
        }, (error) => {
            console.log(error.text);
            setIsSuccess(false);
            setIsFail(true);
      });
  }

  const handleSortBy = () =>{
    if(sortBy === 'default'){
        filteredEvents.sort((a,b) =>{
            if (a.id < b.id){
                return -1;
            } else if (a.id > b.id){
                return 1;
            }
            return 0;
        })
    } else if (sortBy === 'alpha'){
        filteredEvents.sort((a,b) =>{
            if (a.name < b.name){
                return -1;
            } else if (a.name > b.name){
                return 1;
            }
            return 0;
        })
    }
    else if (sortBy === 'distance'){
        // todo calculate distance given address of location and location of user
    }
    else if (sortBy === 'location') {
        filteredEvents.sort((a,b) =>{
            if (a.location < b.location){
                return -1;
            } else if (a.location > b.location){
                return 1;
            }
            return 0;
        })
    }
    setIsSortBy(false);
  }

  const formatDate = (date) => {
    return new Date(date).toLocaleString('en-US', {
      weekday: 'short',
      year: 'numeric',
      month: 'short',
      day: 'numeric',
    });
  }

  if (!filteredEvents) {
    return (<Typography>....</Typography>)
  }
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
     <AppBar position="static">
        <Toolbar
            onClick={()=>setIsToggleDrawer(true)}
        >
            <IconButton
                size="large"
                edge="start"
                color="inherit"
                aria-label="menu"
                sx={{ mr: 2 }}
            >
                <MenuIcon />
            </IconButton>
            <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                Around Everything
            </Typography>
        </Toolbar>
     </AppBar>

      <Container maxWidth="md" sx={{ overflowY: 'auto', height: '100vh'}}>
        <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
          <TextField
            label="Search Events"
            variant="outlined"
            value={compSearch}
            onChange={(e) => setCompSearch(e.target.value)}
            fullWidth
            sx={{
              mr: 2,
              '@media (max-width: 600px)': {
                fontSize: '1rem', // Adjust font size for mobile
                padding: '8px', // Reduce padding on smaller screens
              },
            }}
          />
          <DatePicker value={date} onChange={handleDateChange} sx={{
            '@media (max-width: 600px)': {
              fontSize: '1rem',
              padding: '8px',
            },
          }} />
        </Box>

        <FormControl>
            <FormControlLabel
                control={
                <Checkbox
                    checked={isCompOnlyChecked}
                    onChange={handleIsOnlyCompany}
                />}
                label={"Show registered companies"}
                labelPlacement="end"
            />
        </FormControl>

        {loading ? (
          <Box display="flex" justifyContent="center" mt={4}>
            <CircularProgress />
          </Box>
        ) : (
          filteredEvents.map(event => (
            <Link
                key={event.id}
                to={`/SkeletonEvent/${encodeURIComponent(event.id || ' ')}/${encodeURIComponent(event.name || ' ')}/${encodeURIComponent(event.description || ' ')}/${encodeURIComponent(event.location || ' ')}/${encodeURIComponent(event.address || ' ')}/${encodeURIComponent(event.logo || ' ')}/${encodeURIComponent(event.date || ' ')}/${encodeURIComponent(event.time_string || ' ')}/${encodeURIComponent(event.duration || ' ')}/${encodeURIComponent(event.link || ' ')}`}
                style={{ textDecoration: 'none' }} // Prevent underlining of the link
            >
                <Card sx={{
                  mb: 2,
                  p: 2,
                  '@media (max-width: 600px)': {
                    padding: '10px', // Adjust padding for mobile
                  },
                }}>
                  <CardContent sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: 2, // spacing between image and text
                  }}>
                    <Box sx={{
                      width: 80,
                      height: 80,
                      overflow: 'hidden',
                      borderRadius: '8px',
                      display: 'flex',
                      justifyContent: 'center',
                      flexShrink: 0, // prevents image from shrinking
                    }}>
                      <img src={event.logo} alt="Event" style={{
                        width: '100%',
                        height: '100%',
                        objectFit: 'cover',
                      }} />
                    </Box>

                    <Box sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      gap: 0.5,
                    }}>
                      <Typography variant="h6" fontWeight="bold" sx={{
                        fontSize: '1.2rem',
                        '@media (max-width: 600px)': {
                          fontSize: '1rem',
                        },
                      }}>
                        {event.name}
                      </Typography>

                      <Typography variant="body2" color="textSecondary" sx={{
                        fontSize: '0.9rem',
                        '@media (max-width: 600px)': {
                          fontSize: '0.8rem',
                        },
                      }}>
                        {event.location}
                      </Typography>

                      <Typography variant="body2" color="textSecondary" sx={{
                        fontSize: '0.9rem',
                        '@media (max-width: 600px)': {
                          fontSize: '0.8rem',
                        },
                      }}>
                        {event.time_string}
                      </Typography>
                    </Box>
                  </CardContent>
                </Card>
            </Link>
          ))
        )}
    { /*
      <BottomNavigation
        showLabels
        value={value}
        onChange={(event, newValue) => {
          setValue(newValue);
        }}
        style={{
            position: 'fixed',
            bottom: 0,
            left: 0,
            width: '100%',
            zIndex: 1000,  // Ensures it stays on top
        }}
      >
        <BottomNavigationAction label="Recents" icon={<RestoreIcon />} />
        <BottomNavigationAction label="Favorites" icon={<FavoriteIcon />} />
        <BottomNavigationAction label="Nearby" icon={<LocationOnIcon />} />
      </BottomNavigation>
    */}
        <Drawer open={isToggleDrawer} onClose={()=>setIsToggleDrawer(false)}>
            <List>
              <ListItem disablePadding>
                <ListItemButton onClick={()=>{
                    setIsDonate(true)
                    setIsToggleDrawer(false)
                }}>
                  <ListItemIcon>
                    <InboxIcon />
                  </ListItemIcon>
                  <ListItemText primary="Support the App" />
                </ListItemButton>
              </ListItem>

                <ListItem disablePadding>
                  <ListItemButton onClick={()=>{
                    setIsNewsletter(true)
                    setIsToggleDrawer(false);
                  }}>
                    <ListItemIcon>
                      <InboxIcon />
                    </ListItemIcon>
                    <ListItemText primary="Register to post events" />
                  </ListItemButton>
                </ListItem>

                <ListItem disablePadding>
                  <ListItemButton onClick={()=>{
                    setIsSortBy(true)
                    setIsToggleDrawer(false);
                  }}>
                    <ListItemIcon>
                      <InboxIcon />
                    </ListItemIcon>
                    <ListItemText primary="Sort By" />
                  </ListItemButton>
                </ListItem>
            </List>
        </Drawer>
        <Modal open={isNewsletter} onClose={() => setIsNewsletter(false)}>
          <Box sx={{
            position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)',
            bgcolor: 'background.paper', p: 3, borderRadius: 2, boxShadow: 24, width: 400,
            maxWidth: '90%', '@media (max-width: 600px)': {
              width: '90%',
              padding: 2,
            }
          }}>
            <IconButton sx={{ position: 'absolute', right: 8, top: 8 }} onClick={() => setIsNewsletter(false)}>
                <CloseIcon />
            </IconButton>

           <Typography id="resume" padding="5px 0px" variant="h4">CONTACT ME</Typography>
            <Box>
                <TextField
                  variant="filled"
                  fullWidth
                  required
                  id="fName"
                  label="First Name"
                  margin="dense"
                  value={firstName}
                  onChange={(event)=>setFirstName(event.target.value)}
                />
                <TextField
                    variant="filled"
                    fullWidth
                    required
                    id="lName"
                    label="Last Name"
                    margin="dense"
                    value={lastName}
                    onChange={(event)=>setLastName(event.target.value)}
                />
                <TextField
                    variant="filled"
                    fullWidth
                    required
                    id="companyName"
                    label="Company (optional)"
                    margin="dense"
                    value={company}
                    onChange={(event)=>setCompany(event.target.value)}
                />
                <TextField
                  variant="filled"
                  fullWidth
                  required
                  id="email"
                  label="Email"
                  margin="dense"
                  value={email}
                  onChange={(event)=>setEmail(event.target.value)}
                />
                <TextField
                  variant="filled"
                  fullWidth
                  required
                  id="about-you"
                  label="More about you"
                  value={aboutYou}
                  margin="dense"
                  multiline
                  rows={4}
                  onChange={(event)=>setAboutYou(event.target.value)}
                />
                <Button variant="outlined" onClick={sendEmail}>Submit</Button>
            </Box>
          </Box>
        </Modal>
        <Modal open={isDonate} onClose={() => setIsDonate(false)}>
          <Box sx={{
            position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)',
            bgcolor: 'background.paper', p: 3, borderRadius: 2, boxShadow: 24, width: 400,
            maxWidth: '90%', '@media (max-width: 600px)': {
              width: '90%',
              padding: 2,
            }
          }}>
            <IconButton sx={{ position: 'absolute', right: 8, top: 8 }} onClick={() => setIsDonate(false)}>
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" mb={2}>One-time donations</Typography>
            <Button variant="contained" component="a" href="https://buy.stripe.com/4gwdUN9dG7GCd564gg" target="_blank" fullWidth color={'primary'}
                sx={{ mb: 1, backgroundColor: theme.palette.primary.main }}
            >
                Donate $5
            </Button>
            <Button variant="contained" component="a" href="https://buy.stripe.com/aEU7wp4Xq9OK9SU289" fullWidth sx={{ mb: 1 }}>Donate $10</Button>
            <Button variant="contained" component="a" href="https://buy.stripe.com/8wMdUN9dG6CyghicMO" fullWidth sx={{ mb: 1 }}>Donate $25</Button>
            <Button variant="contained" component="a" href="https://buy.stripe.com/4gw4kd9dGe50e9adQT" fullWidth sx={{ mb: 1 }}>Donate $50</Button>
            <Button variant="contained" component="a" href="https://buy.stripe.com/14kcQJfC47GC0ikfZ2" fullWidth sx={{ mb: 1 }}>Custom</Button>

            <Typography variant="h6" mb={2}>Recurring donations</Typography>
            <Button variant="contained" component="a" href="https://buy.stripe.com/9AQdUN0HabWS1mo4gl" fullWidth sx={{ mb: 1 }}>$60/month</Button>
            <Button variant="contained" component="a" href="https://buy.stripe.com/8wM9Ex89Cf943uwdQW" fullWidth sx={{ mb: 1 }}>$150/month</Button>
          </Box>
        </Modal>
        <Modal open={isSortBy} onClose={() => setIsSortBy(false)}>
            <Box sx={{
              position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)',
              bgcolor: 'background.paper', p: 3, borderRadius: 2, boxShadow: 24, width: 400,
              maxWidth: '90%', '@media (max-width: 600px)': {
                width: '90%',
                padding: 2,
              }
            }}>
                <IconButton sx={{ position: 'absolute', right: 8, top: 8 }} onClick={() => setIsSortBy(false)}>
                    <CloseIcon />
                </IconButton>

                <FormControl>
                    <FormLabel id="sort-by-form-label">SORT BY</FormLabel>
                    <RadioGroup
                        name="sort-by-radio-buttons-group"
                        value={sortBy}
                        onChange={(e)=>{setSortBy(e.target.value)}}
                    >
                        <FormControlLabel value="default" control={<Radio />} label="Default" />
                        <FormControlLabel value="alpha" control={<Radio />} label="Alphabetical" />
                        <FormControlLabel value="location" control={<Radio />} label="Location" />
                    </RadioGroup>
                </FormControl>
                <Button variant="contained" onClick={handleSortBy}>Sort</Button>
           </Box>
        </Modal>
      </Container>
    </LocalizationProvider>
  );
}
